// @flow
// Material helpers
import { withStyles } from "@material-ui/core";
import React from "react";
import { hotjar } from "react-hotjar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Welcome from "../../containers/welcome";
import {
  doHandleSubLevelOption,
  doLoadFullConversation,
  doLoadUserAction,
  doRegisterAdditionalData,
  doSendBotMessage,
  doSendBotMessageId,
  doSendUserMessage,
  doShowUserAutocompleteOptions,
  doShowUserFreeText,
  doShowUserMultipleOptions,
  doShowUserOptions,
  doShowUserSubLevelOptions,
  doUnloadUserAction,
  getRandomInt,
  resetConversation
} from "../../modules/find-bot";
import AutoComplete from "./components/AutoComplete";
import Bubble from "./components/Bubble";
import FreeText from "./components/FreeText";
import MultipleOptions from "./components/MultipleOptions";
import Options from "./components/Options";
import SubLevelOptions from "./components/SubLevelOptions";
import UserOptionsWaiting from "./components/UserOptionWaiting";
//Component styles
import styles from "./styles";

class FindBot extends React.Component {
  constructor(props) {
    super(props);

    this.bubblesEl = React.createRef();
    this.bottomEl = React.createRef();
    this.userActionsEl = React.createRef();

    this.updateUserActionsHeight = this.updateUserActionsHeight.bind(this);

    this.state = {
      userActionsHeight: 0
    };
  }
  updateUserActionsHeight(h) {
    this.setState({
      userActionsHeight: h
    });
  }

  handleWindowBeforeUnload(event) {
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = '';
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    this.props.resetConversation();
  }
  componentDidMount() {

    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);

    this.props.resetConversation();
    this.props.doLoadFullConversation().then(() => {
      let firstMsg = this.props.fullConversation.length
        ? this.props.fullConversation[0]
        : null;

      if (firstMsg) {
        if (typeof firstMsg.msg === "string") {
          this.props.doSendBotMessage(firstMsg);
        } else if (typeof firstMsg.msg === "function") {
          firstMsg.msg(this.props.replies).then(data => {
            firstMsg.msg = data;
            this.props.doSendBotMessage(firstMsg);
          });
        }
      }

      if (process.env.REACT_APP_ENV === "PROD") hotjar.initialize(2569060, 6);
    });
  }
  componentDidUpdate() {
    if (this.state.userActionsHeight !== this.userActionsEl.offsetHeight) {
      this.setState({
        userActionsHeight: this.userActionsEl.offsetHeight
      });
    }
    this.bottomEl.scrollIntoView({ behavior: "smooth" });
  }
  render() {
    const { classes, fullConversation } = this.props;

    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ''; // Legacy method for cross browser support
      }
      return ''; // Legacy method for cross browser support
    };

    if (fullConversation.length === 0) {
      return <Welcome />;
    } else {
      return (
        <div className={classes.root} styles={{ marginoverflow: "hidden", margin: 0, height: "100%" }}>
          {this.props.conversation.map((e, key) => {
            return (
              <Bubble
                {...this.props}
                {...e}
                key={key}
                mainBottomEl={this.bottomEl}
                bottomEl={this.bottomEl}
              />
            );
          })}
          <div
            id="userActionsEl"
            ref={el => {
              this.userActionsEl = el;
            }}
          >
            {this.props.loadingUserAction && <UserOptionsWaiting />}
            {this.props.autocompleteOptions && (
              <AutoComplete
                {...this.props}
                updateUserActionsHeight={this.updateUserActionsHeight}
              />
            )}
            {this.props.options && <Options {...this.props} />}
            {this.props.multipleOptions && (
              <MultipleOptions msgFunction={this.props.msg} {...this.props} />
            )}
            {this.props.subLevelOptions && <SubLevelOptions {...this.props} />}
            {this.props.freeText && <FreeText {...this.props} />}
          </div>

          <div
            ref={el => {
              this.bottomEl = el;
            }}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  user: state.loading.user,
  conversation: state.findBot.conversation,
  fullConversation: state.formData.fullConversation,
  options: state.findBot.options,
  multipleOptions: state.findBot.multipleOptions,
  freeText: state.findBot.freeText,
  maxSelected: state.findBot.maxSelected,
  autocompleteOptions: state.findBot.autocompleteOptions,
  subLevelOptions: state.findBot.subLevelOptions,
  _next: state.findBot._next,
  msg: state.findBot.msg,
  additionalData: state.findBot.additionalData,
  valueKey: state.findBot.valueKey,
  evalValue: state.findBot.evalValue,
  replies: state.findBot.replies,
  loadingUserAction: state.findBot.loadingUserAction,
  lastMsg: state.findBot.lastMsg,
  userType: state.profile.profile.userType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetConversation,
      doLoadFullConversation,
      doSendBotMessageId,
      doSendBotMessage,
      doSendUserMessage,
      getRandomInt,
      doShowUserOptions,
      doShowUserMultipleOptions,
      doShowUserSubLevelOptions,
      doHandleSubLevelOption,
      doShowUserAutocompleteOptions,
      doRegisterAdditionalData,
      doShowUserFreeText,
      doLoadUserAction,
      doUnloadUserAction,
      dispatch
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FindBot))
);
